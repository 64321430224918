<template>
  <div class="staff-container">
    <section class="table-search">
      <div class="search-L">
        <el-select v-model="tableParams.processType" placeholder="选择类型" clearable class="mr15" @change="reload">
          <el-option v-for="item in processTypeList" :key="item.codeValue" :label="item.codeLabel"
            :value="item.codeValue">
          </el-option>
        </el-select>
        <el-select v-model="tableParams.stop" placeholder="请选择状态" clearable @change="reload" class="mr15">
          <el-option v-for="item in ObjToOpt(departmentStatus)" :key="item.value" :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" v-has="'staff_add'" @click="onAdd()">新增流程</el-button>
      </div>
    </section>
    <Table :tableData="tableData" :column="settingColumns" border :headerCellStyle="headerCellStyle">
      <template slot-scope="scope" slot="stop">
        <span>{{ scope.row.stop == 1 ? '停用' : '正常' }}</span>
      </template>
      <template slot-scope="scope" slot="remark">
        <el-tooltip class="" effect="dark" :content="scope.row.remark" placement="bottom-start">
          <div class="text-ellipsis">{{ scope.row.remark }}</div>
        </el-tooltip>
       
      </template>
      <template slot-scope="scope" slot="operation">
        <el-button type="text" size="medium" @click.stop="onEdit(scope.row)" v-has="'staff_edit'">编辑</el-button>
        <el-button type="text" size="medium" @click.stop="onSwitch(scope.row)" v-has="'staff_edit'">{{ scope.row.stop ==
          1
          ? '启用' : '停用' }}</el-button>

        <el-button type="text" size="medium" v-if="scope.row.stop == 1" @click.stop="onDel(scope.row)"
          v-has="'staff_del'">删除</el-button>
      </template>
    </Table>
    <div class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { settingColumns } from "@/db/tableColumns";
import {
  queryProcessList, delProcess, queryProcessTypeList, enableProcess, stopProcess
} from "@/api/examine/process.js";
import {
  departmentStatus,
} from "@/db/objs"
import {
  ObjToOpt,
} from "@/utils/utils"
import { rules } from "@/db/rules";
export default {
  data() {
    return {
      departmentStatus,
      settingColumns,
      headerCellStyle: {
        background: '#FAFAFA',
        color: '#000000',
      },
      ObjToOpt,
      // table列表
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],
      tableParams: {
        page: 1,
        pageSize: 10,
        processType: '',//流程类型
        stop: 0,//是否停用
      },
      processTypeList: [],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.queryProcessTypeList();
      this.queryProcessList();
    },
    // 查询选择类型列表
    queryProcessTypeList() {
      queryProcessTypeList().then(res => {
        if (res.isSuccess == 'yes') {
          this.processTypeList = res.data;
        } else {
          this.$message.error(res.message)
        }
      })
    },

    // 查询流程列表
    queryProcessList() {
      queryProcessList(this.tableParams).then(res => {
        if (res.isSuccess == 'yes') {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.queryProcessList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.queryProcessList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】新增用户
    onAdd() {
      this.$router.push({
        path: "/examine/base",
      })
    },
    // 【监听】编辑按钮
    onEdit(row) {
      this.$router.push({
        path: "/examine/base?processId=" + row.processId,
      })
    },
    onSwitch(row) {
      if (row.stop == '1') {
        this.$confirm('是否确定启用?', '启用', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = {
            processId: row.processId,
          }
          enableProcess(data).then(res => {
            this.reload();
          })
        });
      } else {
        this.$confirm('是否确定停用?', '停用', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = {
            processId: row.processId,
          }
          stopProcess(data).then(res => {
            this.reload();

          })
        });
      }
    },
    // 【监听删除】
    onDel(row) {
      let data = {
        processId: row.processId
      }
      this.$confirm('请确保已做好员工工作交接，是否确定删除?', '删除员工', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        delProcess(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.queryProcessList();
          }
        })
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.staff-container {
  padding: .15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}

.text-ellipsis {
  // 超过两行显示省略号
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  line-height: 1.5;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}
</style>